html {
    height: 100%;
    box-sizing: border-box;
    font-family: "Source Sans Pro", sans;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
#root {
    position: relative;
    margin: 0;
    height: 100%;
}
pre {
    font-family: "Source Sans Pro", sans;
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word;
}
.nopadding {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

#content {
    min-height: 100%;
    margin-top: 40px;
}

h1.title-header {
    line-height: 0.6;
}

.footer {
    min-height: 100px;
    position: relative;
    bottom: 0;
    right: 0;
}
.footer a {
    color: #74a3ff;
}
.footer a:focus,
.footer a:hover {
    color: #a3c0fb;
}
.loading {
    animation: 0.25 fadeIn;
    animation-fill-mode: forwards;
    visibility: hidden;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.8);
}
.nav-item .active {
    font-weight: 600;
    border-bottom: 1px solid white;
}

.navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position: relative;
}
.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
}
/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
    text-decoration: underline;
}

.dropdown .nav-link {
    cursor: pointer !important;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0%;
    min-width: 10rem;
    padding: 0.2rem 0.2rem;
    margin: 0rem;
    background-color: var(--primary);
}

.dropdown-item {
    cursor: pointer !important;
    color: rgba(255, 255, 255, 0.8) !important;
    font-weight: 400;
    white-space: normal;
    word-wrap: break-word;
    min-width: 160px;
}
.dropdown-menu .nav-item .nav-link {
    padding: 0.25rem 1.5rem;
    color: rgba(255, 255, 255);
    cursor: pointer !important;
    min-width: 160px;
}
.dropdown-submenu:hover,
.dropdown-item:hover,
.dropdown-item:focus {
    color: var(--white) !important;
    background-color: rgba(90, 126, 197, 0.65);
}
.dropdown-submenu > .dropdown-menu {
    position: absolute;
    top: -10%;
    left: 100%;
    font-weight: 500;
    min-width: 1rem;
    padding: 0.2rem 0.2rem;
    margin: 0rem;
    white-space: normal;
    word-wrap: break-word;
}
.dropdown-toggle::after {
    vertical-align: 0em;
}
.dropdown-submenu .dropdown-toggle::after {
    vertical-align: 0em;
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

input[type="checkbox"],
input[type="radio"] {
    margin-right: 20px;
}

/** React Autosuggest **/
.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: var(--white);
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    -webkit-transition: border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border: 1px solid var(--dark);
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 35px;
    width: 100%;
    border: 1px solid var(--dark);
    background-color: var(--light);
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: var(--light);
    border: 1px solid var(--dark);
}

.react-autosuggest__suggestion-match {
    color: var(--dark);
    font-weight: bold;
}

.autosuggest .suggestion-close {
    cursor: pointer;
}

/** Drag and Drop Payload Styles **/
.dropzone-container {
    width: 100%;
    border: 1px solid var(--light);
    background-color: var(--light);
}
.dropzone .base {
    margin: 20px;
    width: calc(100% - 40px);
    height: 200px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid var(--dark);
    background-color: var(--light);
    border-style: dashed;
}
.dropzone .base p {
    margin-top: 20px;
    font-weight: 600;
    font-size: 1.1em;
}

.dropzone .accept {
    margin: 20px;
    width: calc(100% - 40px);
    text-align: center;
    border: 1px solid var(--primary);
    border-style: solid;
}
.dropzone .reject {
    margin: 20px;
    width: calc(100% - 40px);
    text-align: center;
    border: 1px solid var(--danger);
    border-style: solid;
}

.dropzone-container .alert {
    margin: 20px;
    width: calc(100% - 40px);
}

@keyframes fadeIn {
    99% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

/**Search Results Styling**/
.search-result {
    margin-bottom: 15px;
    background: var(--light);
}
.search-result-deactivated {
    background: #fff;
}
.search-result a {
    color: #597dc5;
}
.search-result-deactivated a {
    color: #aaa;
}
.search-result .text-inner {
    padding: 20px 20px;
    height: 100%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.search-result .header {
    /* margin-bottom: 20px; */
}
.search-result .header .to {
    color: var(--dark);
    letter-spacing: 0.08em;
    font-weight: 700;
    text-decoration: none;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 250ch;
}
.search-result .header h5 {
    line-height: 1em;
}
.search-result p {
    font-weight: 400;
    color: var(--dark);
    font-size: 0.95em;
    overflow: hidden;
    position: relative;
    line-height: 1.25em;
    max-height: 3.75em;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
}

.search-result-deactivated p {
    color: aaa;
}
.search-result p:before {
    content: "...";
    position: absolute;
    right: 0;
    bottom: 0;
}
.search-result p:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    background: var(--light);
}

.search-result .search-result-footer {
    /* margin-top: 20px; */
}
.search-result .search-result-footer .title {
    font-size: 1em;
    font-weight: 400;
    color: var(--gray);
}
.search-result-deactivated .search-result-footer .title {
    color: #aaa;
}
.search-result .search-result-footer .title::after {
    content: "|";
    display: inline-block;
    margin: 0 7px;
    font-size: 0.9em;
    color: #ccc;
}
.search-result .to a,
.search-result .search-result-footer .title a {
    color: var(--gray) !important;
}
.search-result-deactivated .to a,
.search-result-deactivated .search-result-footer .title a {
    color: #aaa !important;
}
.search-result .search-result-footer .title-last::after {
    display: none;
}
/** Detailed **/
.detailed,
.detailed-quicklinks {
    margin-bottom: 20px;
}

.detailed .header-links {
    color: var(--dark);
    letter-spacing: 0.08em;
    font-weight: 700;
    text-decoration: none;
    font-size: 0.9em;
    margin-bottom: 10px;
    border: 1px solid var(--light);
    padding: 20px;
    width: 100%;
}

.detailed .details h1:not(.slate-h1) {
    color: var(--dark);
    line-height: 1.1em;
    margin-bottom: 20px;
}

.detailed .details .detailed-footer .title {
    font-weight: 400;
    color: var(--gray);
    max-width: 100%;
    border-right: 1px dotted var(--gray);
}
.detailed .details .detailed-footer .email {
    text-transform: none !important;
}

.detailed .details .detailed-footer .title-last {
    border-right: none;
}
.detailed .details .detailed-footer h6 {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: var(--gray);
    margin-right: 5px;
    font-size: 1em;
    max-width: 90px;
}
.media .media-body .main h6.title-role {
    margin-bottom: 0px;
}
.media .media-body .main div.role {
    color: var(--gray);
    font-size: 0.8em;
    margin-top: -3px;
}
.media .media-body .main div.role a span.mb-auto {
    color: var(--dark);
}
.detailed .details .detailed-footer .organization::after {
    content: ", ";
}
.detailed .details .detailed-footer .organization:last-child::after {
    content: "";
}
.detailed .details .body {
    margin-top: 40px;
}
.detailed .details .body p:not(.slate-p) {
    margin-bottom: 30px;
}

.detailed .details .blockquote {
    font-size: 1.05em;
    line-height: 1.7em;
    border: 1px solid var(--light);
    border-left: 4px solid var(--light);
    padding: 20px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 30px;
}
.detailed .details .blockquote p {
    font-weight: 400;
    color: var(--gray);
    text-transform: capitalize;
}
.detailed .details .tablestructure {
    font-size: 1.05em;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0px 10px;
}
.detailed .details .tablestructure:nth-child(2n) {
    background-color: var(--light);
}
.detailed .tags .tag {
    padding: 5px 25px;
    border: 1px solid #ddd;
    margin: 5px;
    color: #777;
    font-size: 0.75em;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    border-radius: 50px;
}
.detailed .nav {
    padding-top: 100px;
}
/**QuickLinks Widget**/
.quick-links-widget {
    margin-bottom: 40px;
    padding: 10px;
    border: 1px solid var(--light);
}
.quick-links-widget header {
    margin-bottom: 20px;
    font-size: 1.2em;
}
.quick-links-widget a {
    display: block;
    padding-left: 10px;
    margin-bottom: 20px;
}
span.override-link a {
    color: #212529;
}

/** Payloads List **/
.items .item {
    background: var(--light);
    padding: 10px;
    color: var(--gray);
}
.items .item:nth-of-type(2n + 2) {
    background: none;
}

/** nav quick links back **/
.nav .back {
    margin-top: 50px;
}

/** New or Edit Forms **/
form:not(.nav-item) {
    margin-top: 30px;
}

/** Rich text editor toolbar **/
.form-group .rich-text-editor {
    padding: 0px 10px;
    background: rgb(255, 255, 255);
    border: 1px solid #ced4da;
    border-radius: 0;
    min-height: 250px;
}

.form-group .rich-text-editor .toolbar {
    position: relative;
    padding: 1px 5px;
    margin: 0 -10px;
    border-bottom: 1px solid #ced4da;
    margin-bottom: 10px;
    background-color: #eee;
}
blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
}
.inline-block {
    display: inline-block;
}

/**Text Overflow **/
.wrap-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.font-bold {
    font-weight: 600;
}
/** Ribbon **/
/* The ribbons */
/* common */
.ribbon {
    width: 60px;
    height: 30px;
    background-color: #a2a2a2;
    overflow: hidden;
    position: relative;
    top: 10px;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 60px;
    color: #fff;
    text-align: center;
}
.ribbon::before,
.ribbon::after {
    border-top-color: transparent;
    border-left-color: transparent;
}

/** Tables **/
.table-filter td,
.table-filter th {
    padding: 0.2rem;
    vertical-align: top;
    border: 1px solid #dee2e6;
}
.payload-header {
    margin-bottom: 0em;
}

.mmw-90 {
    min-width: 90px;
    max-width: 90px;
}
/** Buttons **/

.btn-outline-primary:hover {
    color: #0e111b;
    background-color: #c9d7f3;
    border-color: #5a7ec5;
}
.btn-primary:hover {
    color: #fff;
    background-color: #597dc5 !important;
    border-color: #597dc5 !important;
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary.disabled:hover {
    color: var(--white);
    background-color: #5291d2;
    border-color: #5291d2;
}
.btn-outline-primary.disabled:hover {
    color: #5a7ec5;
    background-color: transparent;
}
.nav-item .input-group-append .btn:hover {
    color: #fff;
    background-color: #214198 !important;
    border-color: #214198 !important;
}

/** toast **/
.customToast {
    max-width: 100% !important;
}
.customToast .Toastify__toast .Toastify__toast-body {
    margin: 0px 10px !important;
}
.customToast .Toastify__toast .Toastify__close-button--error:focus {
    outline: none !important;
}
.customToast .Toastify__toast--error {
    background: #bd3d3d;
    border: 1px solid #792929;
}
.customToast .Toastify__toast--success {
    background: #43b343;
    border: 1px solid #448444;
}

.text-underline {
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}
.inline-button {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    background-color: white;
}
.box-shadow {
    box-shadow: 0 0 0 3px #b4d5ff;
}
.display-hide {
    display: none;
}

/** Font-awesome **/
.fa-xs {
    font-size: 0.8em;
}

.fa-sm {
    font-size: 0.9em;
}

/** Slate Editor **/
.slate-h1 {
    margin: 1em 0 0 !important;
}
.slate-h2 {
    margin: 0.8em 0 0 !important;
}
.slate-p {
    margin: 0em 0 0 !important;
    padding: 0 !important;
}
.readmode-true .slate-img,
.readmode-true .slate-image > * {
    pointer-events: none !important;
    cursor: default;
}

/* .pointer-events-none {
    pointer-events: none !important;
} */
